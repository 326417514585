import { openNav, closeNav, toggleSubnav } from "./modules/navigation.js";

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});

// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $("#main-navigation").hasClass("-translate-y-full") ? openNav() : closeNav();
});

$(".toggle-subnav").click(function (e) {
  var subnav = $(this).next(".subnav");
  toggleSubnav(subnav);
});

// Carousel
var swiper = new Swiper(".swiper", {
  loop: true,
  theshold: 2,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        ' inline-block w-8 h-1 mx-1 bg-antich-blue rounded-sm transition"></span>'
      );
    },
  },
});

// Stories
$(".story-toggle").click(function () {
  $(".story-toggle")
    .removeClass("text-antich-blue")
    .addClass("text-antich-blue/30");
  $(this).addClass("text-antich-blue").removeClass("text-antich-blue/30");

  $(".story").addClass("hidden");
  $(".story").eq($(this).index()).removeClass("hidden");
});
