export function openNav() {
  $("#main-navigation").removeClass("-translate-y-full");
  $("#hamburger .open").addClass("hidden");
  $("#hamburger .close").removeClass("hidden");
}

export function closeNav() {
  $("#main-navigation").addClass("-translate-y-full");
  $("#hamburger .close").addClass("hidden");
  $("#hamburger .open").removeClass("hidden");

  closeSubnav();
}

export function toggleSubnav(index) {
  index.slideToggle();
  index.parent().find(".subnav-ind").toggleClass("rotate-180");
}

export function closeSubnav() {
  $(".subnav").addClass("hidden").css("display", "");
  $(".subnav-ind").removeClass("rotate-180");
}
